/*
 *  * |///////////////////////////////////////////////////////////////////////|
 *  * |                                                                       |
 *  * | Copyright (C) STELLABLUE INTERACTIVE SERVICES PVT. LTD.               |
 *  * | All Rights Reserved                                                   |
 *  * |                                                                       |
 *  * | This document is the sole property of StellaBlue Interactive          |
 *  * | Services Pvt. Ltd.                                                    |
 *  * | No part of this document may be reproduced in any form or             |
 *  * | by any means - electronic, mechanical, photocopying, recording        |
 *  * | or otherwise - without the prior written permission of                |
 *  * | StellaBlue Interactive Services Pvt. Ltd.                             |
 *  * |                                                                       |
 *  * |///////////////////////////////////////////////////////////////////////|
 *  */

 import { Injectable } from '@angular/core';
 import {BehaviorSubject, Observable} from 'rxjs';

 @Injectable({
   providedIn: 'root'
 })
 export class LastRouteService{
   private aStatus: BehaviorSubject<boolean>;
   private mStatus: BehaviorSubject<boolean>;
   private startIndex: BehaviorSubject<number>;
   private endIndex: BehaviorSubject<number>;
   private paginatorIndex: BehaviorSubject<number>;
   private clickedMenu: BehaviorSubject<any>;
   private clinicName: BehaviorSubject<any>;
   private clinicUrl: BehaviorSubject<any>;
   private loggedinStatus: BehaviorSubject<boolean>;
   private searchpageLoggedInstatus: BehaviorSubject<boolean>;
   private lastLoop: BehaviorSubject<boolean>;
   private medBuyStatus: BehaviorSubject<boolean>;
   private miscBool: BehaviorSubject<boolean>;
   private fromBackBtn: BehaviorSubject<boolean>;

   constructor( ) {
       this.aStatus = new BehaviorSubject<any>(false);
       this.mStatus = new BehaviorSubject<any>(false);
       this.startIndex = new BehaviorSubject<any>(null);
       this.endIndex = new BehaviorSubject<any>(null);
       this.paginatorIndex = new BehaviorSubject<any>(null);
       this.clickedMenu = new BehaviorSubject<any>(null);
       this.clinicName = new BehaviorSubject<any>(null);
       this.clinicUrl = new BehaviorSubject<any>(null);
       this.loggedinStatus = new BehaviorSubject<any>(false);
       this.searchpageLoggedInstatus = new BehaviorSubject<any>(false);
       this.lastLoop = new BehaviorSubject<any>(false);
       this.medBuyStatus = new BehaviorSubject<any>(false);
       this.miscBool = new BehaviorSubject<any>(false);
       this.fromBackBtn = new BehaviorSubject<any>(false);
   }


   getValue(): Observable<any> {
     return this.aStatus.asObservable();
   }
   setValue(newValue): void {
     this.aStatus.next(newValue);
     //console.log(newValue);
   }



   setMsgStatus(newValue): void {
     this.mStatus.next(newValue);
     //console.log(newValue);
   }
   getMsgStatus(): Observable<any> {
     return this.mStatus.asObservable();
   }

   setStartIndex(newValue): void {
     this.startIndex.next(newValue);
     //console.log(newValue);
   }
   getStartIndex(): Observable<any> {
     return this.startIndex.asObservable();
   }

   setEndIndex(newValue): void {
     this.endIndex.next(newValue);
     //console.log(newValue);
   }
   getEndIndex(): Observable<any> {
     return this.endIndex.asObservable();
   }

   setPaginatorIndex(newValue): void {
     this.paginatorIndex.next(newValue);
     //console.log(newValue);
   }
   getPaginatorIndex(): Observable<any> {
     return this.paginatorIndex.asObservable();
   }

   setClickedMenu(newValue): void {
     this.clickedMenu.next(newValue);
   }
   getClickedMenu(): Observable<any> {
     return this.clickedMenu.asObservable();
   }

   setClinicName(newValue): void {
     this.clinicName.next(newValue);
   }
   getClinicName(): Observable<any> {
     return this.clinicName.asObservable();
   }

   setClinicUrl(newValue): void {
     this.clinicUrl.next(newValue);
   }
   getClinicUrl(): Observable<any> {
     return this.clinicUrl.asObservable();
   }

   setLoggedinStatus(newValue): void {
     this.loggedinStatus.next(newValue);
   }
   getLoggedinStatus(): Observable<any> {
     return this.loggedinStatus.asObservable();
   }
   setSearchpageLoggedInstatus(newValue): void {
     this.searchpageLoggedInstatus.next(newValue);
   }

   getSearchpageLoggedInstatus(): Observable<any> {
     return this.searchpageLoggedInstatus.asObservable();
   }

   setLastLoop(newValue): void {
     this.lastLoop.next(newValue);
   }

   getLastLoop(): Observable<any> {
     return this.lastLoop.asObservable();
   }

   setMedBuyStatus(newValue): void {
     this.medBuyStatus.next(newValue);
   }

   getMedBuyStatus(): Observable<any> {
     return this.medBuyStatus.asObservable();
   }

   setMiscBool(newValue): void {
     this.miscBool.next(newValue);
   }

   getMiscBool(): Observable<any> {
     return this.miscBool.asObservable();
   }

   setFromBackBtn(newValue): void {
     this.fromBackBtn.next(newValue);
   }

   getFromBackBtn(): Observable<any> {
     return this.fromBackBtn.asObservable();
   }

 }
