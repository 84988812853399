import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { GetSet } from '../../../core/utils/getSet';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { BroadcastService } from '../../../core/services/broadcast.service';
import { SBISConstants } from '../../../SBISConstants';
import { ToastService } from 'src/app/core/services/toast.service';
import { CoreService } from 'src/app/core/core.service';

@Component({
  selector: 'app-confirmation-page',
  templateUrl: './confirmation-page.component.html',
  styleUrls: ['./confirmation-page.component.css']
})
export class ConfirmationPageComponent implements OnInit, OnDestroy {
  @Input('paymentState') paymentState: string;
  @Input('confirmationMsg') confirmationMsg: any;
  @Input('buttonName') actionButtonName: string;
  @Input('buttonTwoName') actionButtonTwoName: string;
  @Input('paymentFor') paymentFor: string;
  @Input('headerText') headerText: string;

  appointmentResp: any;

  constructor(
    private activatedRoute: ActivatedRoute,private coreService: CoreService, private toastService: ToastService,
    private router: Router,
    private broadcastService: BroadcastService,
  ) { 
    let action = this.activatedRoute.snapshot.paramMap.get("confirmationFor");
  }

  ngOnInit() {
    GetSet.setPaymentRetryBoolean('');
    this.appointmentResp = GetSet.getAppointmentState();
    if(this.paymentFor == SBISConstants.PAYMENT_FOR.PHARMACY) {
      console.log(this.paymentState);
      console.log(document.getElementById("confirm"));
      document.getElementById("confirm").classList.add("left-align-for-order-medicine");
    }
  }

  ngOnDestroy() {
    if(document.getElementsByClassName('left-align-for-order-medicine').length > 0) {
      document.getElementById('confirm').classList.remove('left-align-for-order-medicine');
    }
  }

  routeToPage() {
    if(this.paymentFor == "ADD_USER_OPD") {
      GetSet.setAddAnotherUserBoolean(false);
      this.router.navigate([SBISConstants.ROUTE_URL_JSON[this.paymentFor]]);
    } else if(this.paymentFor == "PACKAGE_BOOKING_OPD") {
      this.router.navigate(['/view-package-details']);
    }
    else if(this.paymentFor == "OPD_DOCTOR_LIST_MISC"){
      this.router.navigate(['/opd/opd-doctor-list-misc'])

    } else if(this.paymentFor == 'SUBSCRIBED') {
      let subscription_from = localStorage.getItem('subscription_from');
      if(subscription_from == 'AFTER_SIGNUP_SUBSCRIPTION') {
        let regw = JSON.parse(localStorage.getItem('regw'));
        if(regw.currentStepNo == 1) {
          this.router.navigate(['/billing/plan']);
        } else {
          this.router.navigate(['/doctor/profile']);
        }
      } else if(subscription_from == 'RENEW_SUBSCRIPTION_FOR_CHAMBER_ADD') {
        this.router.navigate(['/doctor/chamber']);
        this.broadcastService.setDoctorSubscriptionCheck(true);
      } else {
        this.router.navigate(['/searchPatient']);
        this.broadcastService.setDoctorSubscriptionCheck(true);
      }
      localStorage.removeItem('subscription_from');
    }
    else {
      this.router.navigate([SBISConstants.ROUTE_URL_JSON[this.paymentFor]]);
    }
  
  }

  routeToRetryPage() {
    if(this.paymentFor == "ADD_USER_OPD") {
      GetSet.setAddAnotherUserBoolean(true);
      this.router.navigate([SBISConstants.ROUTE_URL_JSON[this.paymentFor]]);
    } else if(this.paymentFor == "ADD_ASSISTANT") {
      GetSet.setAddAnotherAssistantBoolean(true);
      this.router.navigate([SBISConstants.ROUTE_URL_JSON[this.paymentFor]]);
    } else if (this.paymentFor == "PACKAGE_BOOKING_OPD") {
      this.router.navigate(['opd/opd-package-booking']);
    }else if  (this.paymentFor == "ADD_USER_PHA") {
      GetSet.setAddAnotherUserBoolean(true);
      this.router.navigate(['opd/adduser/pha']);
    } else {
      GetSet.setPaymentRetryBoolean(SBISConstants.PAYMENT_FOR[this.paymentFor]);
      let paymentGlobal = GetSet.getGlobalConsultationFlag();
      if(paymentGlobal) {
        this.router.navigate(['/payment/razor-pay']);
      } else {
        this.getPaymentMode();
      }
    }
  }

   //get payment mode n route accordingly
   getPaymentMode(){
    this.coreService.getPamentMode().subscribe(res=>{
      if(res.status == 2000) {
        let paymentModeArr = res.data.filter(x=>x.paymentPlatform == SBISConstants.PAYMENT_CONST.WEB && x.paymentModeStatus == SBISConstants.PAYMENT_CONST.ACTIVE);
        
        this.router.navigate([SBISConstants.ROUTE_RETRY_URL_JSON[paymentModeArr[0].paymentMode]]);
      }else{
        this.toastService.showI18nToast("Sorry, some error has been occurred. Please try again.","error");
      }
    },err=>{
      this.toastService.showI18nToast("Sorry, some error has been occurred. Please try again.","error");
    });
  }//end of method

}//end of class
