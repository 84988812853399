/*
 *  * |///////////////////////////////////////////////////////////////////////|
 *  * |                                                                       |
 *  * | Copyright (C) STELLABLUE INTERACTIVE SERVICES PVT. LTD.               |
 *  * | All Rights Reserved                                                   |
 *  * |                                                                       |
 *  * | This document is the sole property of StellaBlue Interactive          |
 *  * | Services Pvt. Ltd.                                                    |
 *  * | No part of this document may be reproduced in any form or             |
 *  * | by any means - electronic, mechanical, photocopying, recording        |
 *  * | or otherwise - without the prior written permission of                |
 *  * | StellaBlue Interactive Services Pvt. Ltd.                             |
 *  * |                                                                       |
 *  * |///////////////////////////////////////////////////////////////////////|
 *  */

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment/moment';
import { environment } from '../../../../environments/environment';
@Pipe({
  name: 'dateFormat',
  pure: true
})
export class DateFormatPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if(!isNaN(Date.parse(value))){
      return moment(value).format(environment.DATE_FORMAT);
    }else{
      return;
    }
  }

  dateRequestPayloadPipe(value: any, args?: any): any {
    let date = new Date(value);
    let dateStr = date.getFullYear() + '-' + ((date.getMonth()+1) < 10 ? ('0' + (date.getMonth()+1)) : (date.getMonth()+1)) + '-' +(date.getDate() < 10 ? ('0' + date.getDate()) : date.getDate())+'T00:00:00.000Z'  //hardcoded time is for non time dependancy
    return dateStr;
  }
}