/*
 *  * |///////////////////////////////////////////////////////////////////////|
 *  * |                                                                       |
 *  * | Copyright (C) STELLABLUE INTERACTIVE SERVICES PVT. LTD.               |
 *  * | All Rights Reserved                                                   |
 *  * |                                                                       |
 *  * | This document is the sole property of StellaBlue Interactive          |
 *  * | Services Pvt. Ltd.                                                    |
 *  * | No part of this document may be reproduced in any form or             |
 *  * | by any means - electronic, mechanical, photocopying, recording        |
 *  * | or otherwise - without the prior written permission of                |
 *  * | StellaBlue Interactive Services Pvt. Ltd.                             |
 *  * |                                                                       |
 *  * |///////////////////////////////////////////////////////////////////////|
 *  */

import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalvideoService {
  onLineStatus:any;
  private statusChange: BehaviorSubject<boolean>;
  private patientName: BehaviorSubject<string>;
  private userRefNumber: BehaviorSubject<any>;
  private userProfileImage: BehaviorSubject<any>;
  private clientStreamStatus: BehaviorSubject<boolean>;
  private disconnectedStatus: BehaviorSubject<boolean>;
  private reConnectedStatus: BehaviorSubject<boolean>;
  private netDestroyedconnectedStatus: BehaviorSubject<boolean>;
  private clientLeftStatus: BehaviorSubject<boolean>;
  private publisherOfflineStatus: BehaviorSubject<boolean>;
  private patientRingtoneStatus: BehaviorSubject<any>;
  private screenSharingStatus: BehaviorSubject<boolean>;
  private subScriberScreenSize: BehaviorSubject<boolean>;
  private publisherSessionEnd: BehaviorSubject<boolean>;
  private reconnectVideoCall: BehaviorSubject<any>;
  private conDestroy: BehaviorSubject<boolean>;
  private doctorRecon: BehaviorSubject<boolean>;
  private closeVid: BehaviorSubject<boolean>;



  constructor( ) {
      this.statusChange = new BehaviorSubject<any>(false);
      this.patientName = new BehaviorSubject<any>(null);
      this.userRefNumber = new BehaviorSubject<any>(null);
      this.userProfileImage = new BehaviorSubject<any>(null);
      this.clientStreamStatus = new BehaviorSubject<any>(true);
      this.disconnectedStatus = new BehaviorSubject<any>(false);
      this.reConnectedStatus = new BehaviorSubject<any>(false);
      this.netDestroyedconnectedStatus = new BehaviorSubject<any>(false);
      this.clientLeftStatus = new BehaviorSubject<any>(false);
      this.publisherOfflineStatus = new BehaviorSubject<any>(false);
      this.patientRingtoneStatus = new BehaviorSubject<any>(null);
      this.screenSharingStatus = new BehaviorSubject<any>(false);
      this.publisherSessionEnd = new BehaviorSubject<any>(false);
      this.subScriberScreenSize = new BehaviorSubject<any>(false);
      this.reconnectVideoCall = new BehaviorSubject<any>(null);
      this.conDestroy = new BehaviorSubject<any>(false);
      this.doctorRecon = new BehaviorSubject<any>(false);
      this.closeVid = new BehaviorSubject<any>(false);
  }


  getValue(): Observable<any> {
    return this.statusChange.asObservable();
  }
  setValue(newValue): void {
    this.statusChange.next(newValue);
    //console.log(newValue);
  }



  setPatientName(newValue): void {
    this.patientName.next(newValue);
    //console.log(newValue);
  }

  getPatientName(): Observable<any> {
    return this.patientName.asObservable();
  }

  setUserRefNumber(newValue): void {
    this.userRefNumber.next(newValue);
    //console.log(newValue);
  }

  getUserRefNumber(): Observable<any> {
    return this.userRefNumber.asObservable();
  }

  setUserProfileImage(newValue): void {
    this.userProfileImage.next(newValue);
    //console.log(newValue);
  }

  getUserProfileImage(): Observable<any> {
    return this.userProfileImage.asObservable();
  }

  setClientStreamStatus(newValue): void {
    this.clientStreamStatus.next(newValue);
    //console.log(newValue);
  }

  getClientStreamStatus(): Observable<any> {
    return this.clientStreamStatus.asObservable();
  }


  setDisconnectedStatus(newValue): void {
    this.disconnectedStatus.next(newValue);
    //console.log(newValue);
  }

  getDisconnectedStatus(): Observable<any> {
    return this.disconnectedStatus.asObservable();
  }


  setReconnectedStatus(newValue): void {
    this.reConnectedStatus.next(newValue);
    //console.log(newValue);
  }

  getReconnectedStatus(): Observable<any> {
    return this.reConnectedStatus.asObservable();
  }


  setNetDestroyedconnectedStatus(newValue): void {
    this.netDestroyedconnectedStatus.next(newValue);
    //console.log(newValue);
  }

  getNetDestroyedconnectedStatus(): Observable<any> {
    return this.netDestroyedconnectedStatus.asObservable();
  }

  setClientLeftStatus(newValue): void {
    this.clientLeftStatus.next(newValue);
    //console.log(newValue);
  }

  getClientLeftStatus(): Observable<any> {
    return this.clientLeftStatus.asObservable();
  }

  setPublisherOfflineStatus(newValue): void {
    this.publisherOfflineStatus.next(newValue);
    //console.log(newValue);
  }

  getPublisherOfflineStatus(): Observable<any> {
    return this.publisherOfflineStatus.asObservable();
  }

  setPatientRingtoneStatus(newValue): void {
    this.patientRingtoneStatus.next(newValue);
    //console.log(newValue);
  }

  getPatientRingtoneStatus(): Observable<any> {
    return this.patientRingtoneStatus.asObservable();
  }

  setScreenSharingStatus(newValue): void {
    this.screenSharingStatus.next(newValue);
    //console.log(newValue);
  }

  getScreenSharingStatus(): Observable<any> {
    return this.screenSharingStatus.asObservable();
  }

  setSubScriberScreenSize(newValue): void {
    this.subScriberScreenSize.next(newValue);
    //console.log(newValue);
  }

  getSubScriberScreenSize(): Observable<any> {
    return this.subScriberScreenSize.asObservable();
  }

  setPublisherSessionEnd(newValue): void {
    this.publisherSessionEnd.next(newValue);
    //console.log(newValue);
  }

  getPublisherSessionEnd(): Observable<any> {
    return this.publisherSessionEnd.asObservable();
  }

  setReconnectVideoCall(newValue): void {
    this.reconnectVideoCall.next(newValue);
    //console.log(newValue);
  }

  getReconnectVideoCall(): Observable<any> {
    return this.reconnectVideoCall.asObservable();
  }


  setConDestroy(newValue): void {
    this.conDestroy.next(newValue);
  }

  getConDestroy(): Observable<any> {
    return this.conDestroy.asObservable();
  }

  setDoctorRecon(newValue): void {
    this.doctorRecon.next(newValue);
  }

  getDoctorRecon(): Observable<any> {
    return this.doctorRecon.asObservable();
  }

  setCloseVid(newValue): void {
    this.closeVid.next(newValue);
  }

  getCloseVid(): Observable<any> {
    return this.closeVid.asObservable();
  }



}
