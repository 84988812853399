/*
 *  * |///////////////////////////////////////////////////////////////////////|
 *  * |                                                                       |
 *  * | Copyright (C) STELLABLUE INTERACTIVE SERVICES PVT. LTD.               |
 *  * | All Rights Reserved                                                   |
 *  * |                                                                       |
 *  * | This document is the sole property of StellaBlue Interactive          |
 *  * | Services Pvt. Ltd.                                                    |
 *  * | No part of this document may be reproduced in any form or             |
 *  * | by any means - electronic, mechanical, photocopying, recording        |
 *  * | or otherwise - without the prior written permission of                |
 *  * | StellaBlue Interactive Services Pvt. Ltd.                             |
 *  * |                                                                       |
 *  * |///////////////////////////////////////////////////////////////////////|
 *  */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ApiService } from './../core/services/api.service';
import { BroadcastService } from './../core/services/broadcast.service';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class CoreService {

  constructor(private apiService: ApiService) { }

  getUserRoles(path): Observable<any> {
    return this.apiService.GetUserRole.getByPath(path);
  }

  getUserState(path): Observable<any> {
    return this.apiService.GetUserState.getByPath(path)
  }

  checkAlertState(query): Observable<any> {
    return this.apiService.checkNewAlerts.postByQuery(query)
  }

  userLogin(query: any): Observable<any> {
    return this.apiService.UserLogin.postByQuery(query);
  }

  setFeedback(query: any): Observable<any> {
    return this.apiService.SetFeedback.postByQuery(query);
  }

  getFeedback(query: any): Observable<any> {
    return this.apiService.GetFeedback.postByQuery(query);
  }

  feedbackDownloadFile(query: any): Observable<any> {
    return this.apiService.FeedbackDownloadFile.postByQuery(query);
  }

  sendDocViaEmail(query: any): Observable<any> {
    return this.apiService.SendDocumentViaEmail.postByQuery(query);
  }
  getAllRolesByUser(query): Observable<any> {
    return this.apiService.GetAllRolesByUser.postByQuery(query);
  }

  //working on system admin issue[set password for a user]-[https://gitlab.com/sbis-poc/app/issues/1008]
  getUSerDetailsBySearchData(query): Observable<any> {
    return this.apiService.GetUserDetailsBySearchData.postByQuery(query);
  }  
  // [https://gitlab.com/sbis-poc/app/issues/1008]
  setPasswordBySysAdmin(query): Observable<any> {
    return this.apiService.SetPasswordBySysAdmin.postByQuery(query);
  }
  
   setEaddressBySysAdmin(query): Observable<any> {
    return this.apiService.SetEaddressBySysAdmin.postByQuery(query);
  }
   //Get Patient Details for Sysadmin
  GetPatientDetailsBySearchData(query): Observable<any>
  {
    return this.apiService.GetPatientDetailsBySearchData.postByQuery(query);
  }
   //Get Patient Details by Order Id for Sysadmin
   GetPatientDetailsByOrderId(query): Observable<any>
   {
     return this.apiService.GetPatientDetailsByOrderId.postByQuery(query);
   }

  //https://gitlab.com/sbis-poc/app/issues/1120
  deleteDocument(query): Observable<any> {
    return this.apiService.DeleteDocument.deleteByQuery(query);
  }

  getCommonRoles(): Observable<any> {
    return this.apiService.RetrieveCommonRoles.get();
  }

  
  // https://gitlab.com/sbis-poc/app/-/issues/2618
  getChatSession(query): Observable<any> {
    return this.apiService.GetChatSession.postByQuery(query);
  }

  closeChatSession(query): Observable<any> { //https://gitlab.com/sbis-poc/app/-/issues/2626
      return this.apiService.CloseChatSession.postByQuery(query);
  }
  // https://gitlab.com/sbis-poc/app/-/issues/2618

  //Fetch Service Provider details by User PK and Parent Role name
  getServiceProviderEntityValueByPk(query): Observable<any> {
    return this.apiService.GetServiceProviderEntityValueByPkv2.postByQuery(query);
  }

  //to verify chat link
  chatVerifyLinkForIndividualUser(query): Observable<any> {
    return this.apiService.ChatVerifyLinkForIndividualUser.postByQuery(query);
  }

  getSessionIdSysAdmin(query): Observable<any> {
    return this.apiService.GetSessionId.getBySearchString(query);
  }
  deactivateDoctorBySysAdmin(query):Observable<any> {
    return this.apiService.DeactivateDoctor.deleteByQuery(query);
  }

  getOrderDetailsSysAdmin(query): Observable<any> {
    return this.apiService.GetOrderDetailsSysAdmin.getBySearchString(query);
  }
  updateApptCancelReasonSysAdmin(query): Observable<any> {
    return this.apiService.UpdateCancelReasonSysAdmin.putByQuery(query);
  }
  getPaymentDetailsByOrderIdSysAdmin(query): Observable<any> {
    return this.apiService.GetPaymentDetailsSysAdmin.getBySearchString(query);
  }
  getOrderDetailsByOrderIdSysAdmin(query): Observable<any> {
    return this.apiService.GetOrderDetailsByOrderId.getBySearchString(query);
  }
  getOrderDetailsByOrderIdSysAdminV2(query): Observable<any> {
    return this.apiService.GetOrderDetailsByOrderIdV2.getBySearchString(query);
  }
  updateUserApptSysAdmin(query): Observable<any> {
    return this.apiService.UpdateUserForApptSysAdmin.putByQuery(query);
  }
  updateApptBySysAdmin(query): Observable<any> {
    return this.apiService.UpdateAppointmentBySysAdmin.putByQuery(query);
  }
  sentPaymentLinkFromSysAdmin(query): Observable<any> {
    return this.apiService.ResentPaymentLinkFromSysAdmin.getBySearchString(query);
  }
  sendPaymentLinkFromSysAdminV2(query): Observable<any> {
    return this.apiService.SendPaymentLinkFromSysAdminV2.postByQuery(query);
  }

   getPaymentLinkFromSysAdmin(query): Observable<any> {
    return this.apiService.GetPaymentLinkFromSysAdmin.getBySearchString(query);
  }
   getPaymentLinkFromSysAdminV2(query): Observable<any> {
    return this.apiService.GetPaymentLinkFromSysAdminV2.getBySearchString(query);
  }

  sendGlobalPaymentLink(query):Observable<any> {
    return this.apiService.sendGlobalPaymentLink.postByQuery(query);
  }
  sendInvoiceViaMail(query):Observable<any> {
    return this.apiService.sendInvoiceViaMail.postByQuery(query);
  }
  viewDoctorAppointmentsSysAdmin(query): Observable<any> {
    return this.apiService.ViewDoctorAppointmentsSysAdmin.getBySearchString(query);
  }
  getSuccessPaymentDetailsByOrderIdSysAdmin(query): Observable<any> {
    return this.apiService.GetSuccessPaymentListFromOrderIdSysAdmin.getBySearchString(query);
  }
  UpdateOrderPaymentStatusBySysAdmin(query): Observable<any> {
    return this.apiService.UpdatePaymentStatusBySysAdmin.putByQuery(query);
  }
  getPaymentByPaymentID(query): Observable<any> {
    return this.apiService.GetPaymentDetailsByPaymentID.getBySearchString(query);
  }

  getPamentMode(): Observable<any> {
    return this.apiService.GetPamentMode.get();
  }

  getMasterDataListByQueryParam(query: any) : Observable<any> {
    return this.apiService.MasterData.getByQuery(query);
  }

  getHolidayCalendarOfDoctorBySysAdmin(query): Observable<any> {
    return this.apiService.GetHolidayListOfDoctorBySysAdmin.getBySearchString(query);
  }

  getSubscribedPlan(): Observable<any> {
    return this.apiService.GetSubscribedPlan.get();
  }

  //Get Diagnostic center list Details for Sysadmin
  getListOfDiagnostcCenters(query): Observable<any> {
    return this.apiService.GetDiagnosticList.getBySearchString(query);
  }

  getListOfActiveNInactiveDiagnostcCenters(query): Observable<any> {
    return this.apiService.GetActiveNInactiveDiagnosticList.getBySearchString(query);
  }
  
  getDiagnostcByRefNo (query): Observable<any> {
    return this.apiService.GetDiagnosticByRefNo.getBySearchString(query);
  }
  
  cancelEntity (query): Observable<any> {
    return this.apiService.CancelEntity.getBySearchString(query);
  }
  
  activateDeactivateEntity (query): Observable<any> {
    return this.apiService.ActivateDeactivateEntity.getBySearchString(query);
  }
  
  getDiagnosticOrderDetails (query): Observable<any> {
    return this.apiService.GetDiagnosticOrderDetails.postByQuery(query);
  }

  UpdateDiagnosticQuote(query: any): Observable<any> {
    return this.apiService.UpdateDiagnosticQuote.postByQuery(query);
  }  
  
  
  getInteractionHistory(query: any): Observable<any> {
    return this.apiService.GetInteractionHistory.postByQuery(query);
  }
  
  getDispositionList(query: any): Observable<any> {
    return this.apiService.GetDispositionList.postByQuery(query);
  }  

  getDispositionMap(query: any): Observable<any> {
    return this.apiService.GetDispositionMap.postByQuery(query);
  }  

 saveInteraction(query: any): Observable<any> {
    return this.apiService.SaveInteraction.postByQuery(query);
  }  

 getChannelPartnerTree (query: any): Observable<any> {
    return this.apiService.GetChannelPartnerTree.postByQuery(query); 
  }  

 UpdateVendorPayment (query: any): Observable<any> {
    return this.apiService.UpdateVendorPayment.postByQuery(query);
  } 

 GetVendorPayment (query: any): Observable<any> {
    return this.apiService.GetVendorPayment.getBySearchString(query);
  } 

RefundPayment (query: any): Observable<any> {
    return this.apiService.RefundPayment.postByQuery(query);
  } 

 UpdateRepeatOrderReminder (query: any): Observable<any> {
    return this.apiService.UpdateRepeatOrderReminder.postByQuery(query);
  } 

getAllChannelPartners (query: any): Observable<any> {
    return this.apiService.GetAllChannelPartners.postByQuery(query); 
  }  

saveChannelPartner (query: any): Observable<any> {
    return this.apiService.SaveChannelPartner.postByQuery(query); 
  }  

activateDeactivateDoctor (query: any): Observable<any> {
    return this.apiService.ActivateDeactivateDoctor.postByQuery(query);
  }  

 getMatchingLabTest(query): Observable<any> {
    return this.apiService.GetMatchingLabTest.getBySearchString(query);
  }
}
